import axios from "axios";
import React, { createContext, useContext, useState, useEffect } from "react";
import { useRouter } from "../routes/hooks";
import { set } from "date-fns";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const router = useRouter();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [userData, setUserData] = useState();
  const [userRole, setUserRole] = useState(
    (userData && userData.role) || "Student"
  );
  const [error, setError] = useState({ login: "", register: "" });

  const storeToken = (serverToken) => {
    setToken(serverToken);
    return localStorage.setItem("token", serverToken);
  };

  let isLoggedIn = !!token;

  const LogoutUser = () => {
    setToken("");
    return localStorage.removeItem("token");
  };
  useEffect(()=>{
    setError({login: "",register:""})
  },[])
  const LoginUser = async (email, password, role) => {
    try {
      
      const response = await axios.post(
        "https://testseriesapi.innoinfotech.in/api/auth/login",
        {
          email,
          password,
          role: role.selected,
        },{
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true // Use this if you need to send cookies
        }
      );
      console.log(response);
      if (response.data.Error) {
        console.log(error,response.data.Error)
        setError({ ...error,login: response.data.Error });
        console.log(error)
        
      } else if (response.data.Message == "Success") {
        console.log("W");
        storeToken(response.data.token);
        router.push("/");
      }else{
      setError({ ...error,login: response.data.Error });
    }
    } catch (error) {
      console.error("Authentication failed:", error);

      if (error.response && error.response.data) {
        setError({ login: error.response.data, ...error }); // Set the error message if present in the error response
      } else {
        setError({
          login: "An unexpected error occurred. Please try again.",
          ...error,
        });
      }
    }
  };

  const RegisterUser = async ({
    email,
    password,
    name,
    setEmail,
    setName,
    setPassword
  }) => {
    try {
      console.log({
        email,
        password,
        name,
        setEmail,
        setName,
        setPassword
      })
     const response = await axios.post(
        "https://testseriesapi.innoinfotech.in/api/auth/register",
        {
          email,
          password,
          name,
        }
      );
      if (response.data.Error) {
        setError({ register: response.data.Error, ...error });
      } else if (response.data.Message == "Success") {
        setEmail("");
        setPassword("");

        setName("");
        router.push("/subscribe");
      }
    } catch (error) {
      console.error("Authentication failed:", error);

      if (error.response && error.response.data) {
        setError({ register: error.response.data, ...error }); // Set the error message if present in the error response
      } else {
        setError({
          register: "An unexpected error occurred. Please try again.",
          ...error,
        });
      }
    }
  };

  const verifyUser = async () => {
    try {
      const response = await axios(
        "https://testseriesapi.innoinfotech.in/api/auth/verifyUser",
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(response);
      if (response.data.Message === "Success") {
        setUserData(response.data.userData);
        setUserRole(response.data.userData && response.data.userData.role);
      } else {
        LogoutUser();
      }
    } catch (error) {
      console.log(error);
      LogoutUser();
    }
  };
  useEffect(() => {
    verifyUser();
  }, []);

  const GetUserList = async () => {
    try {
      const response = await axios.get(
        "https://testseriesapi.innoinfotech.in/api/users/getUsers"
      );
      if (response.data.Error) {
        console.log(response.data.Error);
      } else if (response.data.Message == "Success") {
        return response.data.users;
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <UserContext.Provider
      value={{
        storeToken,
        LogoutUser,
        isLoggedIn,
        verifyUser,
        userData,
        setUserRole,
        userRole,
        GetUserList,
        RegisterUser,
        LoginUser,
        error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = () => {
  return useContext(UserContext);
};

export { useUserContext, UserContext, UserProvider };
