import axios from "axios";
import React, { createContext, useContext, useState, useEffect } from "react";
import { useUserContext } from "./UserContextAPI";
import { useRouter } from "../routes/hooks";

const QuestionPaperContext = createContext();

const QuestionPaperProvider = ({ children }) => {
  const route = useRouter();
  const { userData } = useUserContext();
  const [QuestionList, setQuestionList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [Question, setQuestion] = useState(1);
  const [Submited, setSubmited] = useState(false);
  const [score, setScore] = useState();
  const [testOn, setTestOn] = useState({});

  const ProccedToTest = async (course, subject, chapter) => {
    try {
      setTestOn({ course, subject, chapter });
      const res = await axios.post(
        "https://testseriesapi.innoinfotech.in/api/questionPaper/generateQuestionPaper",
        { course, subject, chapter }
      );
      const data = res.data;
      if (data.message == "Success") {
        setQuestionList(data.questionPaper);
        // setQuestionNumber(QuestionList[0].QuestionNo)
        // QuestionList!=[]&&console.log(data.questionPaper,QuestionList,QuestionNumber)
      } else {
        console.log(data.error, data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const setQuestionNo = (val) => {
    console.log(QuestionList[val - 1].QuestionNo, val);
    setQuestion(QuestionList[val - 1].QuestionNo);
    return QuestionList[val - 1].QuestionNo;
  };

  const getCourse = async () => {
    const res = await axios(
      "https://testseriesapi.innoinfotech.in/api/questionPaper/getCourses"
    );
    if (res.data.message == "Success") {
      return res.data.result;
    }
  };
  const getSubject = async (courses) => {
    if (courses) {
      console.log(courses);
      const res = await axios.post(
        "https://testseriesapi.innoinfotech.in/api/questionPaper/getSubjects",
        {
          course: courses,
        }
      );
      if (res.data.message == "Success") {
        return res.data.result;
      }
    }
  };
   const getSubjectList = async () => {
   
      const res = await axios.get(
        "https://testseriesapi.innoinfotech.in/api/questionPaper/getSubjectList",
       
      );
      if (res.data.message == "Success") {
        return res.data.result;
      }
    
  };
  const getChapter = async (subject) => {
    const res = await axios.post(
      "https://testseriesapi.innoinfotech.in/api/questionPaper/getChapters",
      {
        subject: subject,
      }
    );
    if (res.data.message == "Success") {
      return res.data.result;
    }
  };

  const UploadAnsKeys = async (formData, setFile, courses, setCourses) => {
    try {
      const response = await axios.post(
        "https://testseriesapi.innoinfotech.in/api/questionPaper/upload_answerKey",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.message == "Upload successful") {
        alert("File uploaded successfully");
        setFile("select");
        setCourses({ ...courses, selected: "" });

        getCourse();
      }
    } catch (error) {
      console.error("Error uploading file: ", error);
      alert("Error uploading file");
    }
  };

  const UploadQuestions = async (
    selectedFiles,
    courses,
    subject,
    chapter,
    setSelectedFiles,
    setChapter,
    setCourses,
    setSubject
  ) => {
    const formData = new FormData();
    formData.append("course", courses.selected);

    formData.append("subject", subject.selected);
    formData.append("chapter", chapter.selected);
    for (let i = 0; i < selectedFiles.length; i++) {
      console.log(selectedFiles[i], i, selectedFiles);
      formData.append("image", selectedFiles[i]);
    }
    axios
      .post(
        "https://testseriesapi.innoinfotech.in/api/questionPaper/upload_ques_image",
        formData
      )
      .then((response) => {
        console.log(response);
        if (response.data.message == "Success") {
          setSelectedFiles([]);
          setCourses({ ...courses, selected: "" });
          setSubject({ ...subject, selected: "" });
          setChapter({ ...chapter, selected: "" });
          getChapter();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const SubmitTest = async () => {
    console.log(userData);
    try {
      console.log(selectedOptions);
      let email = userData.email;

      const res = await axios.post(
        "https://testseriesapi.innoinfotech.in/api/questionPaper/submitQuestionPaper",
        { selectedOptions, ...testOn, userData }
      );
      const data = await res.data;
      if (data.message == "Success") {
        setSubmited(true);
        setScore(data.score);
        setSelectedOptions([]);
      } else {
        console.log(data.error, data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = (selectedValue) => {
    if (Question != []) {
      let uniqueArray = selectedOptions.filter(
        (item) => item.QuesNo != Question
      );

      console.log([...uniqueArray, { QuesNo: Question, value: selectedValue }]);
      setSelectedOptions([
        ...uniqueArray,
        { QuesNo: Question, value: selectedValue },
      ]);
      console.log(Question);
 
      setTimeout(() => {
      SubmitTest();
      route.push("/result");
    }, 1010); }
  };
  useEffect(() => {
    setQuestion(QuestionList[0] && QuestionList[0].QuestionNo);
    console.log(
      "QuestionNo",
      QuestionList[0] && JSON.stringify(QuestionList[0].QuestionNo)
    );
  }, [QuestionList]);

  const handlePrevious = async (setSelectedValue, selectedValue) => {
    const setValueOnPrevious = (PreValue) => {
      console.log(
        PreValue,
        selectedOptions.filter((item) => console.log(item.QuesNo == PreValue))
      );
      let currentQuestion = selectedOptions.filter(
        (item) => item.QuesNo == PreValue
      );
      console.log(currentQuestion[0] && currentQuestion[0].value);
      setSelectedValue(currentQuestion[0] ? currentQuestion[0].value : "");
    };
    if (Question != [] && Question > 1) {
      let uniqueArray = selectedOptions.filter(
        (item) => item.QuesNo != Question
      );

      console.log(Question);
      setSelectedOptions([
        ...uniqueArray,
        { QuesNo: Question, value: selectedValue },
      ]);

      let PreValue = setQuestionNo(Question - 2);
      PreValue && setValueOnPrevious(PreValue);
    }
  };

  const handleNext = (setSelectedValue, selectedValue, questions) => {
    // Logic for the next question
    const setValueOnNext = (NextValue) => {
      console.log(
        NextValue,
        selectedOptions.filter((item) => console.log(item.QuesNo == NextValue))
      );
      let currentQuestion = selectedOptions.filter(
        (item) => item.QuesNo == NextValue
      );
      console.log(currentQuestion[0] && currentQuestion[0].value);
      console.log(
        NextValue,
        currentQuestion,
        currentQuestion[0] ? currentQuestion[0].value : ""
      );
      setSelectedValue(currentQuestion[0] ? currentQuestion[0].value : "");
    };

    if (Question != [] && Question - 1 != questions.length) {
      let uniqueArray = selectedOptions.filter(
        (item) => item.QuesNo != Question
      );

      console.log(Question, uniqueArray);
      setSelectedOptions([
        ...uniqueArray,
        { QuesNo: Question, value: selectedValue },
      ]);

      let NextValue = setQuestionNo(Question);
      NextValue && setValueOnNext(NextValue);
    }
  };

  const fetchTestDetails = async (userData, setTestDetails, setTestNo) => {
    const res = await axios.post(
      "https://testseriesapi.innoinfotech.in/api/questionPaper/getTestDetails",
      { userData, ...testOn }
    );
    const data = await res.data;
    if (data.message == "Success") {
      setTestDetails(data.TestInfo);
      setTestNo(data.TestNo);
    }
  };

  const fetchTestRecords = async (userData, setTestRecords) => {
    const res = await axios.post(
      "https://testseriesapi.innoinfotech.in/api/questionPaper/getTestRecords",
      { userData }
    );
    const data = await res.data;
    if (data.message == "Success") {
      setTestRecords(data.testRecord);
    }
  };
const getChapterList=async()=>{
  try {
    const response = await axios.get(
      "https://testseriesapi.innoinfotech.in/api/questionPaper/getChapterList"
    );
    if (response.data.Error) {
      console.log(response.data.Error);
    } else if (response.data.message == "Success") {
      console.log(response.data)
      let data=response.data.result
      let newData=[]
      data.map((item)=>{
          newData.push(item)
      })
      console.log(newData)
      return newData
    }
  } catch (error) {
    console.error(error);
  }
}
const EditChapter=async(id,chapter,subject)=>{
  try {
    console.log(id)
    const response = await axios.put(
      "https://testseriesapi.innoinfotech.in/api/questionPaper/editChapter",{
     
          id,chapter,subject
        
      }
    );
    if (response.data.Error) {
      console.log(response.data.Error);
    } else if (response.data.message == "Success") {
     console.log("Edit chapter successfully");
     return "Success"
    }
  } catch (error) {
    console.error(error);
  }
}


const DeleteChapter=async(id)=>{
  try {
    console.log(id)
    const response = await axios.delete(
      "https://testseriesapi.innoinfotech.in/api/questionPaper/deleteChapter",{
        data:{
          id
        }
      }
    );
    if (response.data.Error) {
      console.log(response.data.Error);
    } else if (response.data.message == "Success") {
     console.log("delete chapter successfully");
     return "Success"
    }
  } catch (error) {
    console.error(error);
  }
}



const GetQuestionBank=async()=>{
  try {
    const response = await axios.get(
      "https://testseriesapi.innoinfotech.in/api/questionPaper/getQuestionBank"
    );
    if (response.data.Error) {
      console.log(response.data.Error);
    } else if (response.data.message == "Success") {
      console.log(response.data)
      return response.data.result;
    }
  } catch (error) {
    console.error(error);
  }
}

const DeleteQuestion=async(id)=>{
  try {
    console.log(id)
    const response = await axios.delete(
      "https://testseriesapi.innoinfotech.in/api/questionPaper/deleteQuestion",{
        data:{
          id
        }
      }
    );
    if (response.data.Error) {
      console.log(response.data.Error);
    } else if (response.data.message == "Success") {
     console.log("delete Question successfully");
     return "Success"
    }
  } catch (error) {
    console.error(error);
  }
} 

  return (
    <QuestionPaperContext.Provider
      value={{
        fetchTestRecords,
        fetchTestDetails,
        getCourse,
        getChapter,
        getSubject,
        ProccedToTest,
        handleSubmit,
        handleNext,
        handlePrevious,
        QuestionList,
        Question,
        UploadAnsKeys,
        setQuestionNo,
        UploadQuestions,
        selectedOptions,
        setQuestion,
        setSelectedOptions,
        SubmitTest,
        score,
        testOn,
        getChapterList,
        DeleteChapter,EditChapter,        getSubjectList,
        DeleteQuestion,
        GetQuestionBank
      }}
    >
      {children}
    </QuestionPaperContext.Provider>
  );
};

const useQuestionContext = () => {
  return useContext(QuestionPaperContext);
};

export { useQuestionContext, QuestionPaperContext, QuestionPaperProvider };
