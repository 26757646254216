import { lazy, Suspense, useContext, useEffect } from "react";
import { Outlet, Navigate, useRoutes } from "react-router-dom";

import DashboardLayout from "../layouts/dashboard";
import RegisterPage from "../pages/register";
import QuestionBankPage from "../pages/upload-ques-img";
import { UserContext } from "../context/UserContextAPI";
import { useRouter } from "./hooks";
import EndTest from "../pages/end-test";
import {UploadChapter} from "../sections/upload_chapter";
import UploadChapterPage from "../pages/chapter";
import UploadQuestionPage from "../pages/question";

export const LearnerMCQTest = lazy(() => import("../pages/learner--mcq-test"));
export const LearnerMCQSelect = lazy(() => import("../pages/learner--mcq-select"));

export const AllTestRecords = lazy(() => import("../pages/all-test-records"));
export const Subscription = lazy(() => import("../pages/subscribe"));

export const ResultPage = lazy(() => import("../pages/result"));
export const UploadQuestionImgPage = lazy(() =>
  import("../pages/upload-ques-img")
);
export const UploadAnswerKeyPage = lazy(() =>
  import("../pages/upload-answer-key")
);
export const IndexPage = lazy(() => import("../pages/app"));
export const BlogPage = lazy(() => import("../pages/blog"));
export const UserPage = lazy(() => import("../pages/user"));
export const LoginPage = lazy(() => import("../pages/login"));

export const LogoutPage = lazy(() => import("../pages/logout"));
export const ProductsPage = lazy(() => import("../pages/products"));
export const Page404 = lazy(() => import("../pages/page-not-found"));

// ----------------------------------------------------------------------

export default function Router() {
  // const { isLogin, logout, token, login,userData } = useContext(UserContext);
  // console.log(isLogin);
  // useEffect(() => {
  //   isLogin==false||userData==[] ? logout() : login(token);
  // }, []);
  const router = useRouter();
  const routes = useRoutes([
    {
      element: (
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: "/user", element: <UserPage /> },
        { path: "/products", element: <ProductsPage /> },
        { path: "/blog", element: <BlogPage /> },
        { path: "/upload_ques_img", element: <UploadQuestionImgPage /> },
        { path: "/upload_answer_key", element: <UploadAnswerKeyPage /> },
        { path: "/chapter", element: <UploadChapterPage /> },
        { path: "/upload_question", element: <UploadQuestionPage /> },
        {          path: "/result",          element: <ResultPage />,        },
        // {
        //   path: "end-test",
        //   element: <EndTest />,
        // },
        { path: "/MCQ_test", element: <LearnerMCQTest /> },
       
        { path: "/MCQ_select", element: <LearnerMCQSelect /> },
        { path: "/Test_Record", element: <AllTestRecords /> },
      ],
    },
    {
      path: "/login",
      element: <LoginPage />,
    },

    {
      path: "/register",
      element: <RegisterPage />,
    },
    {
      path: "/subscribe",
      element: <Subscription />,
    },

    {
      path: "/logout",
      element: <LogoutPage />,
    },

    {
      path: "/404",
      element: <Page404 />,
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
